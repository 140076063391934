








import { Vue, Component } from "vue-property-decorator";
import StaffTable from "@/components/staff/StaffTable.vue";
import UserCreateDialog from "@/components/dialogs/UserCreateDialog.vue";
import UserUpdateDialog from "@/components/dialogs/UserUpdateDialog.vue";
import { UserModel } from "@/api/generated";

@Component({ components: { StaffTable, UserCreateDialog, UserUpdateDialog } })
export default class UserOverview extends Vue {
  private updateUserId = "";

  private onUpdateStaff(user: UserModel) {
    if (!user.userId) {
      return;
    }

    this.$router.push({
      name: "Staff Edit Overview",
      params: { userId: user.userId }
    });
  }
}
