

















































































import { Component, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import CountryAutocomplete from "@/components/common/country/CountryAutocomplete.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import {
  StringStringConstantModel,
  UserCreateModel,
  AgencyModel
} from "@/api/generated";
import api from "@/api";

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { CountryAutocomplete } })
export default class UserCreatetDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private agencySelect = false;
  private userRoles: Array<StringStringConstantModel> = [];
  private agencies: Array<AgencyModel> = [];
  private model: UserCreateModel = {
    firstname: "",
    lastname: "",
    emailAddress: "",
    role: ""
  };

  @Watch("model.role")
  private async agencyDropdown() {
    if (this.model.role == "AGM") {
      try {
        this.agencySelect = true;
        const response = await Api.AgencyService.apiAgencyGet();
        this.agencies = response.data;
      } catch {
        snackbarModule.setSnackbarMessage("Failed to load agencies");
      } finally {
        this.loading = false;
      }
    }
  }

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();

    const response = await api.ConstantApi.apiConstantUserRoleGet();
    this.userRoles = response.data;
  }

  private closeDialog() {
    this.syncedDialog = false;
    this.agencySelect = false;
  }

  private async onUserCreate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await Api.UserService.apiUserPost(this.model);
      snackbarModule.setSnackbarMessage("User created");
      this.$emit("user-created");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
      this.agencySelect = false;
    }
  }
}
