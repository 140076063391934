



























import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import { UserModel } from "@/api/generated";
import { DataTableHeader } from "vuetify";
import nameof from "@/utility/nameof";
import Api from "@/api";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class UserTable extends Vue {
  private readonly maxLoginAttempts = 5;
  private loading = false;
  private items: Array<UserModel> = [];
  private headers: Array<DataTableHeader<UserModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<UserModel>("fullname") },
    { text: "Email Address", value: nameof<UserModel>("emailAddress") }
  ];

  private async created() {
    await this.getStaff();
  }

  private async getStaff() {
    try {
      this.loading = true;
      const response = await Api.UserService.apiUserStaffGet();
      this.items = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve staff");
    } finally {
      this.loading = false;
    }
  }
}
