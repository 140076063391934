















































































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import CountryAutocomplete from "@/components/common/country/CountryAutocomplete.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import {
  StringStringConstantModel,
  UserModel,
  AgencyModel
} from "@/api/generated";
import api from "@/api";

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { CountryAutocomplete } })
export default class UserUpdateDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;
  @Prop(String) userId!: string;

  private loading = false;
  private userRoles: Array<StringStringConstantModel> = [];
  private agencies: Array<AgencyModel> = [];
  private model: UserModel = {
    firstname: "",
    lastname: "",
    role: "",
    enabled: false,
    isStaff: false
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      this.form?.reset();
    }

    const userResponse = await Api.UserService.apiUserUserIdGet(this.userId);
    this.model = userResponse.data;

    const agencyResponse = await Api.AgencyService.apiAgencyGet();
    this.agencies = agencyResponse.data;

    const response = await api.ConstantApi.apiConstantUserRoleGet();
    this.userRoles = response.data;
  }

  private async onUserUpdate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      this.model.agencyCreateIds = this.model.agencyIds;
      await Api.UserService.apiUserUserIdPut(this.userId, this.model);
      snackbarModule.setSnackbarMessage("User saved");
      this.$emit("user-updated");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
